import * as React from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Seo from "../../components/SEO"
import { ContentWithPaddingXl } from "../../components/Common/Containers"
import { SectionHeading } from "../../components/Common/Headings"

const Container = styled.div`
  ${tw`w-full m-auto mt-10 px-5 sm:w-3/5 sm:p-0`}
`

const Content = styled.div`
  ${tw`text-black mb-5`}
  p {
    ${tw`mb-5`}
  }
  strong {
    ${tw`text-secondary-900`}
  }
  h2 {
    ${tw`mb-3 pt-5 font-bold text-2xl text-secondary-900`}
  }
  h3 {
    ${tw`mb-3 pt-5 font-bold text-lg text-secondary-900`}
  }
  img {
    ${tw`m-auto py-10`}
  }
  a {
    ${tw`text-gradient font-semibold border-b-2 border-transparent 
hocus:border-gradient pb-1 transition[all] duration-300`}
  }
`
const Date = styled.div`
  ${tw`text-gray-500 text-sm italic mt-5`}
`
const Image = styled.div`
  ${props =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-128 w-full bg-cover bg-center rounded-lg mb-8`}
`

function BlogPost({ data }) {
  const post = data.markdownRemark.frontmatter
  const { date, heading, imageSrc } = post
  return (
    <>
      <Seo title={heading} />
      <Container id={heading}>
        <ContentWithPaddingXl>
          <Image imageSrc={imageSrc} />
          <SectionHeading>{heading}</SectionHeading>
          <Content
            dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
          />
        </ContentWithPaddingXl>
      </Container>
    </>
  )
}

export default BlogPost

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        date
        heading
        imageSrc
      }
      html
    }
  }
`
